<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="9" class="pr-2">
                <v-row no-gutters class="bg-white" v-if="user != null">
                    <v-col cols="8">
                        <v-card-title class="headline pb-0">{{ user.firstName }} {{ user.lastName }}</v-card-title>
                        <v-card-subtitle class="ma-0 py-0 grey--text text--darken-1">Created: {{ formatDate( user.dateCreated, 'MMM DD, YYYY @ hh:mm a' ) }}</v-card-subtitle>
                        <v-card-subtitle class="pt-0 grey--text text--darken-1">Last login: {{ formatDate( user.lastLogin, 'MMM DD, YYYY @ hh:mm a') == '' ? 'Never' : formatDate( user.lastLogin, 'MMM DD, YYYY @ hh:mm a') }}</v-card-subtitle>
                    </v-col>

                    <v-col cols="4" class="text-right pa-4">
                        <v-chip label small :color="active ? 'success' : 'error'" @click="toggleDialog = true">{{ active ? 'Active' : 'Inactive' }}</v-chip>
                    </v-col>

                    <v-col cols="12">
                        <div class="pa-3">
                            <v-form ref="form">
                                <v-subheader class="pl-0">General Information</v-subheader>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="user.firstName" outlined flat label="First name"></v-text-field>
                                        <v-text-field v-model="user.email" outlined flat label="Email"></v-text-field>
                                        <v-checkbox v-model="user.active" label="Active"></v-checkbox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="user.lastName" outlined flat label="Last name"></v-text-field>
                                        <v-text-field v-model="user.phone" outlined flat label="Phone" v-mask="'(###) ###-####'"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-subheader class="pl-0">Privileges</v-subheader>

                                <v-checkbox v-model="user.privileges.superAdmin" label="Super admin" hide-details></v-checkbox>
                                <v-checkbox v-model="user.privileges.manageUsers" label="Manage users" class="indent" hide-details 
                                    :disabled="!user.privileges.superAdmin"></v-checkbox>
                                <v-checkbox v-model="user.privileges.manageCommunities" label="Manage communities" class="indent" hide-details
                                    :disabled="!user.privileges.superAdmin"></v-checkbox>
                                <v-checkbox v-model="user.privileges.manageReports" label="Manage reports" class="indent" hide-details
                                    :disabled="!user.privileges.superAdmin"></v-checkbox>

                                <v-subheader class="pl-0 mt-5">Locations</v-subheader>

                                <v-data-table :headers="locHeaders" :items="locations">
                                    <template v-slot:item.active="{ item }">
                                        <v-chip small :class="item.active ? 'success' : 'error'">{{ item.active ? 'Yes' : 'No' }}</v-chip>
                                    </template>
                                </v-data-table>

                                <div class="text-right mt-3">
                                    <v-btn depressed color="primary" style="text-transform:none;">Save</v-btn>
                                </div>
                            </v-form>
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="3">
                <div class="bg-white pa-3 mb-3">
                    <v-subheader class="pl-0">Chagen password</v-subheader>

                    <v-text-field v-model="newPwd" label="New password" type="password" outlined dense></v-text-field>
                    <v-text-field v-model="confirmNewPwd" label="Confirm new password" type="password" outlined dense></v-text-field>

                    <v-btn block depressed color="secondary" style="text-transform:none;">Change</v-btn>
                </div>

                <div class="bg-white pa-3 mb-3" v-if="user != null && user.pwdChange">
                    <div class="body-2 mb-3">The user will be forced to change the account password the next time he/she sign in</div>
                    <v-btn block depressed color="warning" style="text-transform: none;">Don't force reset</v-btn>
                </div>

                <div class="bg-white pa-3 mb-3" v-else>
                    <div class="body-2 mb-3">Force user to reset the account password the next time he/she sign in</div>
                    <v-btn block depressed color="secondary" style="text-transform: none;">Force reset</v-btn>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="toggleDialog" width="400">
            <v-card v-if="user != null">
                <v-card-title>
                    Warning
                </v-card-title>
                <v-card-text>
                    Are you sure you want to {{ user.active ? 'deactivate' : 'activate' }} this user?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="activate" style="text-transform:none;">Yes</v-btn>
                    <v-btn depressed color="primary" @click="toggleDialog = false" style="text-transform:none;">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import { mask } from 'vue-the-mask';

export default {
    props: {
        userId          : { type: [String, Number], default: null },
    },
    data: () => ({
        user            : null,
        loading         : false,
        toggleDialog    : false,
        active          : false,

        locHeaders      : [
            { text: 'Name', value: 'name', sortable: true },
            { text: 'Active', value: 'active', sortable: true, align: 'right' }
        ],

        newPwd          : '',
        confirmNewPwd   : '',
    }),
    computed: {
        ...mapGetters({
            storeLocations: 'locations/locations',
        }),
        locations() {
            if( _st.isNUE( this.storeLocations ) )
                return [];

            let userLocations = _st.extractProp( this.user.locations, 'locationId' );
            return this.storeLocations.filter(l => userLocations.includes( l.id ));
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadLocations   : 'locations/load',
            activateUser    : 'users/activate',
        }),
        async init() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/user/${this.userId}`);
                this.loading = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage(res.data.message, res.data.error ? 'error' : 'warning');
                    return;
                }

                this.user = res.data.data;
                this.active = this.user.active;

                await this.loadLocations();
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage(error, 'error');
            }
        },
        async activate() {
            try {
                let res = await this.activateUser( this.user.id );

                if( res.status !== false ) 
                    this.active = res.data;
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
            }
        },
    },
    directives: {
        mask
    }
}
</script>

<style lang="scss" scoped>
.bg-white {
    background-color: #fff;
    border-radius: 5px;
}
.indent {
    margin-left: 20px;
}
</style>